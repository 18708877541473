import { Options, Vue } from 'vue-class-component';
// import { event } from 'vue-gtag';
@Options({})
export default class SocialsAndBooking extends Vue {
    private book(): void {
        // event('clicked-BookingButton-Home', {});

        const c = '';
        const mail = 'info@rudetins.ch';
        const title = 'Anfrage';
        const openUrl = `mailto:${mail}?subject=${title}&body=${c}`;
        window.open(openUrl, '_blank');
    }
}

import { Options, Vue } from 'vue-class-component';
// import { event } from 'vue-gtag';
import { useStore } from 'vuex';

@Options({})
export default class Navigation extends Vue {
    private store = useStore();

    private hideMenu(): void {
        this.store.state.showMenu = false;
    }

    private goTo(link: string): void {
        this.hideMenu();
        this.$router.push(link);
    }

    private goToExternal(link: string): void {
        // event('clicked-externalLink-Nav', { link: link });

        this.hideMenu();
        window.open(link, '_blank');
    }

    private isHome(): boolean {
        if (this.$route.name == 'Home') return true;

        return false;
    }

    private switchLanguage(lang: string): void {
        this.$i18n.locale = lang;
        localStorage.setItem('rt-lang', lang);
        this.hideMenu();
    }

    private book(): void {
        // event('clicked-BookingButton-Nav', {});

        const c = '';
        const mail = 'info@rudetins.ch';
        const title = 'Anfrage';
        const openUrl = `mailto:${mail}?subject=${title}&body=${c}`;
        window.open(openUrl, '_blank');
    }
}

<template>
    <div
        :class="{ 'show': true, 'dark': darkMode, 'clickable': !noLink && link }"
        @click="openExternalLink(link)">
        <div class="cal-container">
            <div class="box">
                <div class="month">{{ toLocalMonthName(date) }}</div>
                <div class="day">{{ toDay(date) }}</div>
            </div>
        </div>
        <div class="info-container">
            <div class="dates">{{ toLocalDate(date) }}</div>
            <div class="title">{{ title }}</div>
            <div class="link">
                <a  v-if="!noLink && link" :href="link">
                    <span class="material-icons">local_activity</span>
                    <span>&nbsp;TICKETS</span>
                </a>
                <span v-else>
                    &nbsp;
                </span>
            </div>
        </div>
    </div>
</template>

<script src="./ShowButton.ts"></script>
<style lang="scss" scoped>
@import './ShowButton.scss';
</style>

export default {
    misc: {
        accept: 'Ok',
    },
    navigation: {
        home: 'Home',
        about: 'Band',
        shows: 'Shows',
        shop: 'Shop',
        downloads: 'Media',
        book: 'Book',
    },
    home: {
        advert: 'A Picture of Tomorrow is now available!',
    },
    shows: {
        title: 'Shows',
        upcomingShows: 'Upcoming shows',
        pastShows: 'Past shows',
        moreShows: 'More shows',
        archive: 'Archive',
    },
    about: {
        title: 'Band',
        bigText1: 'The feeling that the world is spinning faster and faster is intensifying. Our future promises to be exciting and yet no one can answer the question of where the journey will take us. The brass punk rockers from Rude Tins confront contemporary issues and deal with current world events. They do this without pathos and empty words, but with their own stories and the conviction that the last word has not yet been spoken.',
        bigText2: 'Rude Tins have their origins in Aargau. Over the past almost 20 years, the 8-piece brass punk rock band has released three LPs and played countless concerts in Switzerland, Germany, Austria, Belgium, Italy and Slovenia. Before the Covid break, the Rude Tins released 4 singles and were rewarded with inclusion in curated playlists on Spotify. The Swiss band will then support US legends Mad Caddies on their European tour. They received the Negative White Award for their live show. With their unique style, the band surprises and hits the nerve of the times.',
    },
    downloads: {
        title: 'Media',
        logos: 'Logos',
        photo: 'Image',
        biography: 'Biography',
        stagerider: 'Stagerider',
    },
    cta: {
        bookUsNow: 'Book us now!',
    },
    cookie: {
        text: 'We use cookies & Google Analytics to offer you the best possible browsing experience. The data collected with the help of cookies & Google Analytics is used to optimize our website.',
    },
};

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import installI18n from './lang/index';
// import VueGtag from 'vue-gtag';

const app = createApp(App);
installI18n(app);

const vueGtagConfig = {
    config: { id: 'G-DX6TGQYT8D' },
};

app.use(store);
app.use(router);
// app.use(VueGtag, vueGtagConfig, router);
app.mount('#app');

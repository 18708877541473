export class ThemeColorHelper {
    private themeColorMeta: HTMLElement | null = null;

    private getThemeColorMeta(): HTMLElement | null {
        if (this.themeColorMeta) {
            return this.themeColorMeta;
        } else {
            const themeColorMeta = document.querySelector('meta[name=theme-color]');

            if (!themeColorMeta) {
                console.warn('ThemeColorHelper: Could not get Theme Color Meta.');
                return null;
            } else {
                this.themeColorMeta = themeColorMeta as HTMLElement;
                return this.themeColorMeta;
            }
        }
    }

    public setThemeColorLight(): void {
        const themeColorMeta = this.getThemeColorMeta();

        if (!themeColorMeta) {
            console.warn('ThemeColorHelper: Could not get Theme Color Meta.');
            return;
        }

        themeColorMeta.setAttribute('content', '#ffffff');
    }

    public setThemeColorDark(): void {
        const themeColorMeta = this.getThemeColorMeta();

        if (!themeColorMeta) {
            console.warn('ThemeColorHelper: Could not get Theme Color Meta.');
            return;
        }

        themeColorMeta.setAttribute('content', '#1b1d1f');
    }
}

import { Options, Vue } from 'vue-class-component';
// import { event } from 'vue-gtag';

@Options({
    props: {
        date: Date,
        title: String,
        link: String,
        darkMode: Boolean,
        noLink: Boolean,
    },
})
export default class ShowButton extends Vue {
    private date!: string;
    private title!: string;
    private link!: string;
    private darkMode!: boolean;
    private noLink!: boolean;

    private toDay(date: Date): string {
        const day = date.getDate();
        return String(day);
    }

    private toLocalDate(date: Date): string {
        const lang = this.$i18n.locale;

        if (lang == 'de') {
            return date.toLocaleString('de-CH', { year: 'numeric', month: 'long', day: 'numeric' });
        } else {
            return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
        }
    }

    private toLocalMonthName(date: Date): string {
        const lang = this.$i18n.locale;

        if (lang == 'de') {
            return date.toLocaleString('de-CH', { month: 'short' });
        } else {
            return date.toLocaleString('en-US', { month: 'short' });
        }
    }

    private openExternalLink(link: string): void {
        if (!link || this.noLink)
            return;

        // event('clicked-ShowButton', { event: this.title });

        window.open(link, '__blank')?.focus();
    }
}

<template>
    <div class="socials-booking">
        <button @click="book()" class="cta">{{ $t('cta.bookUsNow') }}</button>
    </div>
</template>

<script src="./SocialsAndBooking.ts"></script>
<style lang="scss" scoped>
@import './SocialsAndBooking.scss';
</style>

<template>
    <div :class="{ navigation: true, dark: !isHome() }">
        <div @click="hideMenu()" :class="{ 'close-btn': true, dark: !isHome() }">
            <span class="material-icons"> close </span>
        </div>

        <div id="nav">
            <ul>
                <li>
                    <a @click="goTo('/')">{{ $t('navigation.home') }}</a>
                </li>
                <li>
                    <a @click="goTo('/about')">{{ $t('navigation.about') }}</a>
                </li>
                <li>
                    <a @click="goTo('/shows')">{{ $t('navigation.shows') }}</a>
                </li>
                <li>
                    <a @click="goTo('/downloads')">{{ $t('navigation.downloads') }}</a>
                </li>
                <li>
                    <a @click="goToExternal('https://shop.rudetins.ch/')">{{ $t('navigation.shop') }}</a>
                </li>
                <li>
                    <a @click="book()">{{ $t('navigation.book') }}</a>
                </li>
            </ul>
            <div>
                <div class="language-switcher">
                    <div @click="switchLanguage('de')" :class="{ active: $i18n.locale == 'de' }">DE</div>
                    <div @click="switchLanguage('en')" :class="{ active: $i18n.locale == 'en' }">EN</div>
                </div>
                <div class="socials">
                    <div class="social-link">
                        <a href="https://www.facebook.com/rudetins" target="__blank">
                            <i class="fa-brands fa-facebook"></i>
                        </a>
                    </div>
                    <div class="social-link">
                        <a href="https://www.instagram.com/rudetinsofficial/" target="__blank">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </div>
                    <div class="social-link">
                        <a href="https://www.youtube.com/channel/UCbrACoC56TvUUSoRpd9MNNA" target="__blank">
                            <i class="fa-brands fa-youtube"></i>
                        </a>
                    </div>
                    <div class="social-link">
                        <a href="https://open.spotify.com/artist/20XsDMN8RhkHSmnH2DrgAz" target="__blank">
                            <i class="fa-brands fa-spotify"></i>
                        </a>
                    </div>
                    <div class="social-link">
                        <a href="https://soundcloud.com/rudetins" target="__blank">
                            <i class="fa-brands fa-soundcloud"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script src="./Navigation.ts"></script>
<style lang="scss" scoped>
@import './Navigation.scss';
</style>

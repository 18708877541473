export default {
    misc: {
        accept: 'Ok',
    },
    navigation: {
        home: 'Home',
        about: 'Band',
        shows: 'Shows',
        shop: 'Shop',
        downloads: 'Media',
        book: 'Buchen',
    },
    home: {
        advert: 'A Picture of Tomorrow ist jetzt verfügbar!',
    },
    shows: {
        title: 'Shows',
        upcomingShows: 'Kommende Shows',
        pastShows: 'Vergangene Shows',
        moreShows: 'Mehr Shows',
        archive: 'Archiv',
    },
    about: {
        title: 'Band',
        bigText1:
            'Das Gefühl, dass sich die Welt immer schneller dreht, verstärkt sich. Unsere Zukunft verspricht aufregend zu werden und trotzdem kann niemand die Frage beantworten, wohin die Reise führt. Die Brass-Punkrocker von Rude Tins stellen sich den zeitgenössischen Fragen und setzen sich mit dem aktuellen Weltgeschehen auseinander. Dies tun sie, ohne Pathos und leere Worthüllen, dafür mit eigenen Geschichten und der Überzeugung, dass das letzte Wort noch nicht gesprochen ist.',
        bigText2:
            'Ihren Ursprung haben Rude Tins im Aargau. In den letzten fast 20 Jahren veröffentlichte die 8 köpfige Brass-Punkrock Band drei LPs und spielte unzählige Konzerte in der Schweiz, Deutschland, Österreich, Belgien, Italien und Slowenien. Vor der Covid-Pause haben die Rude Tins 4 Singles veröffentlicht und wurden dafür mit der Aufnahme in kuratierte Playlists bei Spotify belohnt. Im Anschluss supporten die Schweizer die US-Legenden Mad Caddies auf ihrer Europa-Tournee. Für ihre Live-Show wurden sie mit dem Negative White Award ausgezeichnet. Mit ihrem einzigartigen Stil überrascht die Band und trifft den Nerv der Zeit.',
    },
    downloads: {
        title: 'Media',
        logos: 'Logos',
        photo: 'Bandfoto',
        biography: 'Biographie',
        stagerider: 'Stagerider',
    },
    cta: {
        bookUsNow: 'Jetzt buchen!',
    },
    cookie: {
        text: 'Wir nutzen Cookies & Google Analytics um dir die bestmögliche Browsing-Erfahrung zu bieten. Die mit Hilfe von Cookies & Google Analytics gesammelten Daten werden zur Optimierung unserer Webseite genutzt.',
    },
};

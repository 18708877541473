import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Home from '@/views/Home/Home.vue';
// import { event } from 'vue-gtag';
import { ThemeColorHelper } from '@/helper/ThemeColorHelper';

const tch = new ThemeColorHelper();

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { darktheme: true },
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About/About.vue'),
    },
    {
        path: '/shows',
        name: 'Shows',
        component: () => import('../views/Shows/Shows.vue'),
    },
    {
        path: '/downloads',
        name: 'Downloads',
        component: () => import('../views/Downloads/Downloads.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

// router.beforeEach((to, from) => {
//     event('router', { from: from, to: to });
// });

router.afterEach((to, from, next) => {
    // Adjust PWA theme color according to page
    if (to.meta.darktheme) {
        tch.setThemeColorDark();
    } else {
        tch.setThemeColorLight();
    }
});

export default router;

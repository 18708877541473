import { Vue, Options } from 'vue-class-component';
import { initializeApp } from 'firebase/app';
import { useStore } from 'vuex';
import Navigation from './components/Navigation/Navigation.vue';
import CookieConsent from './components/CookieConsent/CookieConsent.vue';

@Options({
    components: {
        Navigation,
        CookieConsent,
    },
})
export default class App extends Vue {
    private store = useStore();

    public async mounted(): Promise<void> {
        this.getBrowserLang();

        const firebaseConfig = {
            apiKey: 'AIzaSyD5mqkSNHjDoCbx6pwRxsFA-8Rm9GnZIRE',
            authDomain: 'rudetins-webpage.firebaseapp.com',
            projectId: 'rudetins-webpage',
            storageBucket: 'rudetins-webpage.appspot.com',
            messagingSenderId: '1022344407835',
            appId: '1:1022344407835:web:c2a84b83aa60ce35cb01be',
            measurementId: 'G-1RJPTBL3JW',
        };

        const app = initializeApp(firebaseConfig);
        this.store.state.firebaseApp = app;
    }

    private getBrowserLang(): void {
        let lang = '';
        const lsLang = localStorage.getItem('rt-lang');

        if (lsLang) {
            lang = lsLang;
        } else {
            const navLang = navigator.language;

            if (navLang.length != 2) {
                lang = navLang.split('-')[0];
            } else {
                lang = navLang;
            }
        }

        if (lang == 'en') {
            this.$i18n.locale = 'en';
        } else {
            this.$i18n.locale = 'de';
        }
    }

    private showMenu(): void {
        this.store.state.showMenu = true;
    }

    private isHome(): boolean {
        if (this.$route.name == 'Home') return true;

        return false;
    }
}

<template>
    <div class="new-release-container" @click="openExternalLink('https://shop.rudetins.ch/products/vinyl-a-picture-of-tomorrow')">
        <div class="nr-sub-container">
            <div class="nr-image-container">
                <img src="@/assets/img/apot.png" alt="A Picture of Tomorrow Cover Art" />
            </div>
            <div class="nr-text-container">
                <span> {{ $t('home.advert') }}</span>
            </div>
        </div>
    </div>
</template>

<script src="./NewRelease.ts"></script>

<style lang="scss" scoped>
@import './NewRelease.scss';
</style>

<template>
    <Transition name="fade">
        <div v-show="showShows" class="upcoming-shows">
            <div class="shows-container">
                <div>
                    <h2>{{ $t('shows.upcomingShows') }}</h2>
                    <div class="shows-container-inner">
                        <div class="shows">
                            <ShowButton
                                v-for="gig in upcomingGigs"
                                :key="gig.name + Math.random()"
                                :date="new Date(gig.actualDate.seconds * 1000)"
                                :title="gig.name ? gig.name : gig.location"
                                :link="gig.link" />
                        </div>
                    </div>
                </div>

                <div>
                    <h2>{{ $t('shows.pastShows') }}</h2>
                    <div class="shows-container-inner">
                        <div class="shows pastshows">
                            <ShowButton
                                v-for="gig in pastGigs"
                                :key="gig.name + Math.random()"
                                :noLink="true"
                                :date="new Date(gig.actualDate.seconds * 1000)"
                                :title="gig.name ? gig.name : gig.location"
                                :link="gig.link" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="more">
                <a href="/#/shows">{{ $t('shows.moreShows') }}</a>
            </div>
        </div>
    </Transition>
</template>

<script src="./UpcomingShows.ts"></script>
<style lang="scss" scoped>
@import './UpcomingShows.scss';
</style>

<template>
    <div class="home">
        <img id="header-img" src="@/assets/img/logo.png" alt="Rude Tins" />
        <NewRelease class="content-wide" />
        <UpcomingShows class="content-wide" />
        <Transition name="fade">
            <SocialsAndBooking v-show="showSocials" class="content-wide" />
        </Transition>
    </div>
</template>

<script src="./Home.ts"></script>
<style lang="scss" scoped>
@import './Home.scss';
</style>

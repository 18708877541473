import type { App } from 'vue';
import { createI18n } from 'vue-i18n';
// User defined lang
import enLocale from './_en';
import deLocale from './_de';

const messages = {
    en: {
        ...enLocale,
    },
    de: {
        ...deLocale,
    },
};

const getLocale = () => {
    const cookieLanguage = sessionStorage.getItem('language');

    if (cookieLanguage) {
        return cookieLanguage;
    }

    const language = navigator.language.toLowerCase();
    const locales = Object.keys(messages);
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale;
        }
    }

    return 'de-de';
};

const i18n = createI18n({
    locale: getLocale(),
    messages,
});

// export default i18n
export default (app: App<Element>): void => {
    app.use(i18n);
};

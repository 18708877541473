import { Options, Vue } from 'vue-class-component';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { useStore } from 'vuex';
import ShowButton from '../ShowButton/ShowButton.vue';
import { Gig } from '@/interfaces/gig';

@Options({
    components: {
        ShowButton,
    },
})
export default class UpcomingShows extends Vue {
    private store = useStore();

    private allGigs: Array<Gig> = new Array<Gig>();
    private upcomingGigs: Array<Gig> = new Array<Gig>();
    private pastGigs: Array<Gig> = new Array<Gig>();
    private showShows = false;

    public async mounted(): Promise<void> {
        const urlOne = 'https://lucatescari.github.io/rtgigs/allgigs.json';

        try {
            const response = await fetch(urlOne);

            const test = await response.json();
            this.allGigs = test;
        } catch (e) {
            // Let the errors ratter through
            // console.error('shits fucked');
            // console.log(e);
        }

        this.allGigs = this.allGigs.filter((gig: Gig) => gig.public);

        this.allGigs = this.allGigs.sort((gigA: Gig, gigB: Gig) => {
            if (!gigA.actualDate || !gigB.actualDate) return 0;

            return new Date(gigA.actualDate.seconds * 1000).valueOf() - new Date(gigB.actualDate.seconds * 1000).valueOf();
        });

        (this.store as any).allGigs = this.allGigs;

        this.allGigs.forEach((gig: Gig) => {
            if (gig.actualDate) {
                if (new Date(gig.actualDate.seconds * 1000) >= new Date(Date.now())) {
                    this.upcomingGigs.push(gig);
                }

                if (new Date(gig.actualDate.seconds * 1000) < new Date(Date.now())) {
                    this.pastGigs.push(gig);
                }
            }
        });

        if (this.upcomingGigs.length > 3) this.upcomingGigs = this.upcomingGigs.slice(0, 3);

        if (this.pastGigs.length > 3) this.pastGigs = this.pastGigs.slice(this.pastGigs.length - 3, this.pastGigs.length);

        this.pastGigs = this.pastGigs.reverse();

        this.showShows = true;
    }
}

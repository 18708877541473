<template>
    <Transition name="fadeslide">
        <div v-show="showConsent" class="cookie-consent">
            <div class="text-container">
                {{ $t('cookie.text') }}
            </div>
            <div>
                <button @click="acceptCookie">
                    {{ $t('misc.accept') }}
                </button>
            </div>
        </div>
    </Transition>
</template>

<script src="./CookieConsent.ts"></script>
<style lang="scss" scoped>
@import './CookieConsent.scss';
</style>

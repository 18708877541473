import { Gig } from '@/interfaces/gig';
import { createStore } from 'vuex';

export default createStore({
    state: {
        firebaseApp: null,
        showMenu: false,
        allGigs: new Array<Gig>(),
    },
    mutations: {},
    actions: {},
    modules: {},
});

import { Options, Vue } from 'vue-class-component';
// import { event } from 'vue-gtag';

@Options({})
export default class NewRelease extends Vue {
    private openExternalLink(link: string): void {
        // event('clicked-newRelease-' + link, { accept: true });
        window.open(link, '__blank')?.focus();
    }
}
